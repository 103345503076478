html {
  font-size: 14px;
}
@media only screen and (max-width: 600px) {
  html {
    font-size: 12px;
  }
}
a:focus {
  outline: none;
}
body{
  font-family: Roboto, sans-serif;
  margin: 0;
  font-weight: 300;
}

#root {
  display: flex;
  overflow-y: hidden;
  overflow-x: hidden;
  min-height: 100vh;
}

.zero-padding {
  padding: 0;
}
.zero-all {
  padding: 0;
  margin: 0;
}
.zero-margin {
  margin: 0;
}

@font-face {
  font-family: Roboto;
  src: url("./fonts/Roboto/Roboto-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: Roboto;
  src: url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: Roboto;
  src: url("./fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: Roboto;
  src: url("./fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: "HeliosCondBold";
  src: url("./fonts/HeliosCond/HeliosCondBold/HeliosCondBold.eot");
  src: url("./fonts/HeliosCond/HeliosCondBold/HeliosCondBold.eot?#iefix")format("embedded-opentype"),
  url("./fonts/HeliosCond/HeliosCondBold/HeliosCondBold.woff") format("woff"),
  url("./fonts/HeliosCond/HeliosCondBold/HeliosCondBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}
